<template>
  <div
    class="tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center"
    style="background: white"
  >
    <v-col sm="12" lg="7" class="tw-hidden lg:tw-flex left-side" :style="{backgroundImage:`url(${bg})`}">
      <v-col sm="12" class="left-side-body">
        <img
          :src="logo"
          alt="logo"
          style="height: 80px; width: 286px; cursor: pointer"
          @click="goHome"
        />
        <div class="tw-flex tw-flex-row tw-flex-row tw-items-end socials">
          <p class="admin">Instagram</p>
          <p class="admin"> | </p>
          <p class="admin">LinkedIn</p>
          <p class="admin"> | </p>
          <p class="admin">Twitter</p>
          <p class="admin"> | </p>
          <p class="admin">Facebook</p>
        </div>

      </v-col>
    </v-col>
    <v-col sm="12" lg="5" class="right-side">
      <v-col sm="12" class="right-side-body">
        <img
          :src="logo"
          alt="logo"
          style="height: 34px; width: auto"
          class="tw-flex mb-3 mt-6 tw-cursor-pointer tw-justify-start tw-self-start"
          @click="goHome"
        />
        <h6 class="welcome-buddy md:tw-mb-20 tw-mt-5 md:tw-mt-0">Welcome <span style="font-size: 20px">back buddy!</span> </h6>

        <h6 class="login-header mb-1 mb-md-5">Sign In</h6>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(guardianLogin)">
            <validationProvider
              name="Email"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Email"
                  solo
                  hide-details
                  type="email"
                  v-model="LoginData.email"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>

            <validationProvider
              name="Password"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  color="#F66B05"
                  hide-details
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'text' : 'password'"
                  solo
                  placeholder="Password"
                  name="password"
                  v-model="LoginData.password"
                  id="password"
                >
                </v-text-field>
                <span>{{ errors[0] }}</span>
            </div>
            </validationProvider>
<!--            <div class="tw-py-1">-->
<!--              <v-checkbox color="#F66B05" hide-details class="terms" v-model="agree">-->
<!--                <template #label>-->
<!--                  <label class="tw&#45;&#45;mt-1 tw-pl-3"-->
<!--                  >Keep me Logged In-->
<!--                  </label>-->
<!--                </template>-->
<!--              </v-checkbox>-->
<!--            </div>-->

            <base-button
              class="tw-mt-8"
              width="100%"
              button-text="Sign In"
              :loading="loading"
              type="submit"
            ></base-button>


            <div class="tw-pt-4">
              <h6 class="tw-flex already tw-items-center tw-justify-center">
                Don't have an Account?
                <router-link
                  :to="{ name: 'Register' }"
                  style="color: #F66B05; cursor: pointer"
                  >&nbsp;Signup</router-link
                >
              </h6>
            </div>
            <div class="tw-pt-4">
              <h6 class="tw-flex already tw-items-center tw-justify-center">
                Forgot password?
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                  style="color: #F66B05; cursor: pointer"
                >&nbsp;Forgot password</router-link
                >
              </h6>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-col>

  </div>
</template>

<script>

import BaseButton from "../components/reuseables/BaseButton";
import check from "../assets/check.svg";
import bg from "../assets/sign_up.png"
import logo from "@/assets/logo.svg"
import { login } from "@/services/api/AuthApiService";
export default {
  name: "LoginView",
  components: {  BaseButton, },
  data() {
    return {
      title: "Welcome to Basketball 4 Buckland",
      description: "",
      value: false,
      confirmValue: false,
      dialog: false,
      loading: false,
      check,
      agree: false,
      LoginData: {
        email: "",
        password: "",
      },
      bg,
      logo
    };
  },
  methods: {

    goHome() {
      this.$router.push({name:"HomePage"})
    },
    guardianLogin() {
      if (
        this.validEmail(this.LoginData.email)
      ) {
        this.loading = true;
        login(this.LoginData)
          .then(() => {
            let userData = JSON.parse(sessionStorage.getItem('userData'))
            if (userData.firstLogin){
              this.$router.push({name : 'GuardingOnboarding'})
            }else {
              this.$router.push({ name: 'MainDashboard' })
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$displaySnackbar({
              message: "Email and Password Incorrect",
              success: false,
            });

          });
      }
    },

    validEmail(email) {
      let valid =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(valid)) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1024px) {
  .right-side-body > img {
    display: none;
  }

  .right-side-body .welcome {
    display: none;
  }
  .right-side-body .admin {
    display: none;
  }
}

.right-side-body .welcome {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
}

.right-side-body .admin {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4f4f4f;
  margin-left: 0;
}

.title {
  font-family: "DM Sans",sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004aad;
}
.input {
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.agree {
  font-family: "DM Sans",sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account {
  font-family: "DM Sans",sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.left-side {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.left-side-body {
  display: flex;
  width: 100%;
  //background: rgba(0, 74, 173, 0.51);
  flex-direction: column;
  padding: 5rem 7rem;
  justify-content: flex-start;
}
.buddy {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;
  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-left: 0.8rem;
}

.right-side {
  width: 100%;
  height: auto;
  padding: 0 !important;
  background: white;
}

.right-side-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 7rem;
  justify-content: center;
  @media screen and (max-width: 1440px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px) and (min-width: 1025px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
}

.login-header {
  font-family: 'Inter',sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #F66B05;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.terms {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4f4f4f;
}

.already {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 22px;
  color: #4F4F4F
}

::placeholder {
  color: red !important;
}

.socials{
  height: 96%;
}

.welcome-buddy{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0;
  color: #000000;
}
</style>